import cn from 'classnames';
import React, { forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Option } from 'react-multi-select-component';
import { Wrapper } from 'src/components/Wrapper';
import {
  AnalyticsFormType,
  FillFormFieldBase,
  SuccessfullSubmissionFormAnalyticsType,
  TryFormProps,
  useAnalytics,
} from 'src/hooks/useAnalytics';
import { useGtmDataLayer } from 'src/hooks/useGtmDataLayer';
import { useSubmitForm } from 'src/hooks/useSubmitForm';
import { useTryFormErrorsCheck } from 'src/hooks/useTryFormErrorsCheck';
import { PortalModal } from 'src/layout/PortalModal';
import { DataFromFormInputs, FieldsNamesType, FormStatus } from 'src/types';
import { Checkbox } from 'src/uikit/Form/Checkbox';
import { FormNotice } from 'src/uikit/Form/FormNotice';
import { Input } from 'src/uikit/Form/Input';
import { InputType, ValidationType } from 'src/uikit/Form/Input/constants';
import { MultiSelectControl } from 'src/uikit/Form/MultiSelect';
import { Submit } from 'src/uikit/Form/Submit';
import { TextArea } from 'src/uikit/Form/TextArea';
import { UserAgreementCheckboxes } from 'src/uikit/Form/UserAgreement';
import { CrossIcon } from 'src/uikit/icons';

import s from './Form.module.scss';

type NoticeType = {
  title: string;
  buttonTitle?: string;
};

enum ResultCodes {
  EmailAlreadyExist = '03004',
}

interface NoticeBlockProps {
  formStatus: FormStatus;
  successNotice: NoticeType;
  errorNotice: NoticeType;
  setFormStatus: React.Dispatch<React.SetStateAction<FormStatus>>;
  analyticsButtonId: string;
  formVariant: 'inner' | 'modal' | 'hero';
  showNotice: boolean;
}

export enum FormForEnum {
  OfflineEvent = 'offlineEvent',
  Education = 'education',
  AdditionalMaterials = 'additionalMaterials',
  Default = 'default',
}

function LoadingIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={s.loadingIcon}
    >
      <g id="loader">
        <path
          id="Ellipse 2438 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3636 20C36.3636 10.9626 29.0374 3.63636 20 3.63636V0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40V36.3636C29.0374 36.3636 36.3636 29.0374 36.3636 20Z"
          fill="#26D07C"
        />
      </g>
    </svg>
  );
}

function NoticeBlock({
  formStatus,
  analyticsButtonId,
  successNotice,
  errorNotice,
  setFormStatus,
  formVariant,
  showNotice,
}: NoticeBlockProps) {
  const notice = formStatus === 'success' ? successNotice : errorNotice;

  if ((formStatus !== 'success' && formStatus !== 'error') || !showNotice)
    return null;
  return (
    <>
      {formVariant === 'modal' ? (
        <FormNotice
          title={notice?.title}
          buttonText={notice?.buttonTitle || ''}
          isModal={false}
          className={s.modalNotice}
          onButtonClick={() => setFormStatus('idle')}
          analyticsButtonId={analyticsButtonId}
          isError={formStatus === 'error'}
        />
      ) : (
        <FormNotice
          title={notice?.title}
          buttonText={notice?.buttonTitle || ''}
          isModal={false}
          analyticsButtonId={analyticsButtonId}
          isError={formStatus === 'error'}
        />
      )}
    </>
  );
}

export interface FormBaseProps {
  id?: string;
  title?: string;
  subtitle?: string;
  inputPath: string;
  short?: boolean;
  legalEntity?: boolean;
  formVariant: 'modal' | 'inner' | 'hero';
  successNotice?: Partial<NoticeType>;
  errorNotice?: Partial<NoticeType>;
  buttonTitle?: string;
  webinarsFields?: { [key: string]: string | number };
  fieldsNames?: FieldsNamesType;
  modalClose?: () => void;
  withInn?: boolean;
  multipleComment?: {
    name: string;
    options: Option[];
    label: string;
  };

  productSlug?: string;
  platformSlug?: 'advanced' | 'vmware' | 'evolution' | 'ml-space';
  productTitle?: string;
  eventCategory?: string;
  agreementName?:
    | 'site_services_consent'
    | 'online_event_consent'
    | 'education_consent';
  marketingEventIDs?: string[];
  ispringCourseId?: string;
  additionalFields?: {
    leadSource?: string;
    subsegment?: string;
    priority?: string;
  };

  dataLayer?: {
    event?: string;
    event_category?: string;
    event_action?: string;
    event_label: string;
  };
  analyticsFieldFill: Omit<FillFormFieldBase, 'fieldName'>;
  tryFormType: TryFormProps['formType'];
  allFormsSentFormType: AnalyticsFormType;
  allFormsSentIsPopup?: boolean;
  consultationFormType?: 'header' | 'body';
  saleFormType?: {
    type: 'sale' | 'consultation' | 'calculator' | 'promo';
    platformName:
      | 'Облако VMware'
      | 'Advanced'
      | 'Evolution'
      | 'ML Space'
      | 'combo'
      | 'none';
  };
  withLoader?: boolean;
  withSubscribeForMailing?: boolean;
  isSubscribeForMailingRequired?: boolean;

  formFor?: FormForEnum;
  successfulSubmissonFormAnalytics?: SuccessfullSubmissionFormAnalyticsType;

  classNames?: {
    inner?: string;
  };
}

interface SolutionsProps {
  value: string;
  label: string;
}

function FormBase({
  title,
  subtitle,
  inputPath,
  short = false,
  legalEntity = false,
  formVariant,
  successNotice = {
    title: 'Мы приняли вашу заявку, скоро с вами свяжется специалист.',
  },
  errorNotice = {
    buttonTitle: 'Обновить страницу',
  },
  buttonTitle = 'Отправить',
  webinarsFields,
  fieldsNames,
  productSlug,
  platformSlug,
  agreementName,
  marketingEventIDs,
  ispringCourseId,
  additionalFields,
  dataLayer,
  analyticsFieldFill,
  tryFormType,
  allFormsSentFormType,
  allFormsSentIsPopup,
  consultationFormType,
  saleFormType,
  multipleComment,
  withLoader,
  withInn = false,
  formFor = FormForEnum.Default,
  withSubscribeForMailing = true,
  successfulSubmissonFormAnalytics,
  isSubscribeForMailingRequired = false,
}: FormBaseProps) {
  const dataFields = {
    fields: 'ba68da97a4162765cbb785d623b81814d0d1d00c4b61560f923090d1c83f56aa',
    leadSource:
      'b4137ddb2a845a933ebf6674d9afebc28d6bb532d15e25a26c2135130f378c7d',
    subsegment:
      'cf2cb923cc6a1ba8c56db485216355f94ff3aa4fff2880951382bdaf834e03a6',
    priority:
      '3092a5f0dba05ff3cadc3c2c8eeacd47940b1837f9534bc559d19d8435c8b219',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    resetField,
    watch,
    control,
    setError,
    setValue,
  } = useForm<DataFromFormInputs>({
    mode: 'onChange',
    defaultValues: {
      userAgreement: true,
    },
  });

  const {
    fillFormField,
    tryFormAnalytics,
    consultationFormSent,
    saleFormSent,
  } = useAnalytics();
  const isPhisic = !!watch('isNaturalPerson', false);
  const { sendDataToDataLayer } = useGtmDataLayer();
  const { submitForm, formStatus, setFormStatus, resultCode, formMessage } =
    useSubmitForm();
  const [showNotice, setShowNotice] = useState(true);
  const { successfullSubmissionFormAnalytics } = useAnalytics();

  useTryFormErrorsCheck(errors, isSubmitting, tryFormType);

  useEffect(() => {
    if (
      formStatus === 'error' &&
      resultCode === ResultCodes.EmailAlreadyExist
    ) {
      setError('email', {
        type: 'manual',
        message: formMessage,
      });
      setShowNotice(false);
    } else {
      setShowNotice(true);
    }
  }, [formStatus]);

  const sendFormFillAnalytics = (fieldName: string) => {
    const { formType, popupPlace, buttonId } = analyticsFieldFill;
    if (formType === 'body') {
      fillFormField({
        formType: 'body',
        fieldName: fieldName,
      });
    }
    if (popupPlace && buttonId) {
      fillFormField({
        formType: 'popup',
        fieldName: fieldName,
        popupPlace: popupPlace,
        buttonId: buttonId,
      });
    }

    return null;
  };

  const onSubmit = async (data: DataFromFormInputs) => {
    let comments = null;

    if (Array.isArray(data.comment)) {
      const commentsData = data.comment as SolutionsProps[];

      comments = commentsData?.map(({ label }) => label).join('. ');
    }

    if (formStatus === 'sending') return;
    data = {
      ...data,
      ...webinarsFields,
      marketingEventIDs,
      ispringCourseId,
      referer: document.referrer,
      isNaturalPerson: !!data.isNaturalPerson,
      subscribeForMailing: !!data.subscribeForMailing,
      comment: comments ?? data.comment,
    };

    if (productSlug) {
      data.productSlug = productSlug;
    }

    if (platformSlug) {
      data.platformSlug = platformSlug;
    }

    if (additionalFields) {
      const elmaFields: { [key: string]: string } = {};
      let key: keyof typeof additionalFields;

      for (key in additionalFields) {
        if (additionalFields[key]) {
          elmaFields[dataFields[key]] = additionalFields[key]!;
        }
      }

      data[dataFields.fields] = elmaFields;
    }

    if (data) {
      let other_consents;

      switch (agreementName) {
        case 'online_event_consent':
          delete data.online_event_consent;
          other_consents = ['online_event_consent'];
          break;
        case 'education_consent':
          delete data.education_consent;
          other_consents = ['education_consent'];
          break;
        default:
          delete data.site_services_consent;
          other_consents = ['site_services_consent'];
      }

      await submitForm({
        backendPatchInput: `/forms/${inputPath}`,
        allFormSentFormType: allFormsSentFormType,
        allFormSentIsPopup: allFormsSentIsPopup,
        dataFromFormInputs: {
          ...data,
          other_consents,
        },
        onSuccess: () => {
          tryFormAnalytics({ formType: tryFormType, action: 'Отправлено' });
          if (consultationFormType) {
            consultationFormSent(
              consultationFormType,
              !!data.subscribeForMailing,
            );
          }
          if (saleFormType)
            saleFormSent(
              saleFormType.type,
              !!data.subscribeForMailing,
              saleFormType.platformName,
            );
          sendDataToDataLayer({
            dataLayer,
          });
          successfulSubmissonFormAnalytics &&
            successfullSubmissionFormAnalytics(
              successfulSubmissonFormAnalytics,
            );
          reset();
        },
        onError: () => {
          tryFormAnalytics({
            formType: tryFormType,
            action: 'Ошибка',
            errorCode: resultCode,
          });
        },
      });
    }
  };

  const formErrorNotice = {
    ...errorNotice,
    title: errorNotice?.title ? errorNotice?.title : formMessage,
    buttonTitle: errorNotice?.buttonTitle
      ? errorNotice?.buttonTitle
      : 'Попробовать еще раз',
  };

  const formSuccessNotice = {
    ...successNotice,
    title: successNotice?.title ? successNotice?.title : formMessage,
  };

  const analyticsButtonId =
    analyticsFieldFill.formType === 'popup'
      ? `modal-${analyticsFieldFill.popupPlace}`
      : analyticsFieldFill.formType;

  const isSendingStatus = withLoader && formStatus === 'sending';

  switch (formFor) {
    case FormForEnum.OfflineEvent:
    case FormForEnum.Education:
      return (
        <div className={s.formWithNotice}>
          <div
            className={cn(s.formWrapper, {
              [s.short]: short,
              [s.modal]: formVariant === 'modal',
              [s.hidden]:
                formStatus !== 'idle' && formStatus !== 'sending' && showNotice,
            })}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              data-qa="section_form"
              className={cn(s.form, { [s.isSending]: isSendingStatus })}
            >
              <div className={s.header}>
                {title && <h2 className={s.title}>{title}</h2>}
                {subtitle && <h2 className={s.subtitle}>{subtitle}</h2>}
              </div>
              <div className={s.double}>
                <Input
                  name="name"
                  label={fieldsNames?.name?.label ?? 'Имя'}
                  inputType={InputType.Text}
                  validationType={ValidationType.Name}
                  required
                  errors={errors}
                  register={register}
                  resetField={resetField}
                  className={s.input}
                  onBlur={(event) => {
                    if (event.currentTarget.value) {
                      sendFormFillAnalytics('name');
                    }
                  }}
                />
                <Input
                  name="surname"
                  label="Фамилия"
                  inputType={InputType.Text}
                  validationType={ValidationType.Surname}
                  required
                  errors={errors}
                  register={register}
                  resetField={resetField}
                  className={s.input}
                  onBlur={(event) => {
                    if (event.currentTarget.value) {
                      sendFormFillAnalytics('surname');
                    }
                  }}
                />
              </div>

              <div className={s.double}>
                <Input
                  name="email"
                  inputType={InputType.Text}
                  validationType={ValidationType.Email}
                  label={fieldsNames?.email?.label ?? 'Электронная почта'}
                  errors={errors}
                  register={register}
                  resetField={resetField}
                  required
                  className={s.input}
                  onBlur={(event) => {
                    if (event.currentTarget.value) {
                      sendFormFillAnalytics('email');
                    }
                  }}
                />

                <Input
                  name="phone"
                  inputType={InputType.Phone}
                  label={fieldsNames?.phone?.label ?? 'Телефон'}
                  required
                  errors={errors}
                  register={register}
                  resetField={resetField}
                  className={s.input}
                  onBlur={(event) => {
                    if (event.currentTarget.value) {
                      sendFormFillAnalytics('tel');
                    }
                  }}
                />
              </div>

              {!short && !legalEntity && (
                <Checkbox
                  className={s.checkbox}
                  errors={errors}
                  required={false}
                  register={register}
                  name="isNaturalPerson"
                  value="true"
                  label={fieldsNames?.checkbox?.text ?? 'Я физлицо'}
                />
              )}

              {!short && (
                <>
                  <div className={s.double}>
                    <Input
                      name="company"
                      validationType={ValidationType.Company}
                      inputType={InputType.Text}
                      label={fieldsNames?.company?.label ?? 'Название компании'}
                      required
                      errors={errors}
                      register={register}
                      disabled={isPhisic}
                      resetField={resetField}
                      className={s.input}
                      onBlur={(event) => {
                        if (event.currentTarget.value) {
                          sendFormFillAnalytics('company');
                        }
                      }}
                    />
                    <Input
                      name="position"
                      validationType={ValidationType.Position}
                      inputType={InputType.Text}
                      label={fieldsNames?.position?.label ?? 'Должность'}
                      errors={errors}
                      register={register}
                      disabled={isPhisic}
                      resetField={resetField}
                      required
                      className={s.input}
                      onBlur={(event) => {
                        if (event.currentTarget.value) {
                          sendFormFillAnalytics('position');
                        }
                      }}
                    />
                  </div>

                  {multipleComment && (
                    <MultiSelectControl
                      options={multipleComment.options}
                      control={control}
                      name={multipleComment.name}
                      label={multipleComment.label}
                      errors={errors}
                      required
                    />
                  )}
                </>
              )}

              <UserAgreementCheckboxes
                errors={errors}
                register={register}
                agreementName={agreementName}
                sendFormFillAnalytics={sendFormFillAnalytics}
                fieldsNames={{
                  userAgreement: fieldsNames?.userAgreement,
                  checkboxSubscribe: fieldsNames?.checkboxSubscribe,
                }}
              />

              <Submit>{buttonTitle}</Submit>
            </form>
            {isSendingStatus && <LoadingIcon />}
          </div>
          <NoticeBlock
            formStatus={formStatus}
            successNotice={formSuccessNotice}
            analyticsButtonId={analyticsButtonId}
            errorNotice={formErrorNotice}
            setFormStatus={setFormStatus}
            formVariant={formVariant}
            showNotice={showNotice}
          />
        </div>
      );
    case FormForEnum.AdditionalMaterials:
      return (
        <div className={s.formWithNotice}>
          <div
            className={cn(s.formWrapper, {
              [s.short]: short,
              [s.modal]: formVariant === 'modal',
              [s.hidden]:
                formStatus !== 'idle' && formStatus !== 'sending' && showNotice,
            })}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              data-qa="section_form"
              className={cn(s.form, { [s.isSending]: isSendingStatus })}
            >
              <div className={s.header}>
                {title && <h2 className={s.title}>{title}</h2>}
                {subtitle && <h2 className={s.subtitle}>{subtitle}</h2>}
              </div>

              <Input
                name="email"
                inputType={InputType.Text}
                validationType={ValidationType.Email}
                label={fieldsNames?.email?.label ?? 'Электронная почта'}
                errors={errors}
                register={register}
                resetField={resetField}
                required
                className={s.input}
                onBlur={(event) => {
                  if (event.currentTarget.value) {
                    sendFormFillAnalytics('email');
                  }
                }}
              />

              <UserAgreementCheckboxes
                errors={errors}
                register={register}
                agreementName={agreementName}
                sendFormFillAnalytics={sendFormFillAnalytics}
                withSubscribeForMailing={withSubscribeForMailing}
                fieldsNames={{
                  userAgreement: fieldsNames?.userAgreement,
                  checkboxSubscribe: fieldsNames?.checkboxSubscribe,
                }}
                isSubscribeForMailingRequired={true}
                onlyMail={true}
              />

              <Submit>{buttonTitle}</Submit>
            </form>
            {isSendingStatus && <LoadingIcon />}
          </div>
          <NoticeBlock
            formStatus={formStatus}
            successNotice={formSuccessNotice}
            analyticsButtonId={analyticsButtonId}
            errorNotice={formErrorNotice}
            setFormStatus={setFormStatus}
            formVariant={formVariant}
            showNotice={showNotice}
          />
        </div>
      );
    default:
      return (
        <div className={s.formWithNotice}>
          <div
            className={cn(s.formWrapper, {
              [s.short]: short,
              [s.modal]: formVariant === 'modal',
              [s.hidden]:
                formStatus !== 'idle' && formStatus !== 'sending' && showNotice,
            })}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              data-qa="section_form"
              className={cn(s.form, { [s.isSending]: isSendingStatus })}
            >
              <div className={s.header}>
                {title && <h2 className={s.title}>{title}</h2>}
                {subtitle && <h2 className={s.subtitle}>{subtitle}</h2>}
              </div>
              <div className={s.double}>
                <Input
                  name="name"
                  label={fieldsNames?.name?.label ?? 'Имя'}
                  inputType={InputType.Text}
                  validationType={ValidationType.Name}
                  required
                  errors={errors}
                  register={register}
                  resetField={resetField}
                  className={s.input}
                  onBlur={(event) => {
                    if (event.currentTarget.value) {
                      sendFormFillAnalytics('name');
                    }
                  }}
                />
                <Input
                  name="phone"
                  inputType={InputType.Phone}
                  label={fieldsNames?.phone?.label ?? 'Телефон'}
                  required
                  errors={errors}
                  register={register}
                  resetField={resetField}
                  className={s.input}
                  onBlur={(event) => {
                    if (event.currentTarget.value) {
                      sendFormFillAnalytics('tel');
                    }
                  }}
                />
              </div>

              <div
                className={cn({
                  [s.double]: withInn,
                })}
              >
                <Input
                  name="email"
                  inputType={InputType.Text}
                  validationType={ValidationType.Email}
                  label={fieldsNames?.email?.label ?? 'Электронная почта'}
                  errors={errors}
                  register={register}
                  resetField={resetField}
                  required
                  className={s.input}
                  onBlur={(event) => {
                    if (event.currentTarget.value) {
                      sendFormFillAnalytics('email');
                    }
                  }}
                />

                {withInn && (
                  <Input
                    label="ИНН"
                    validationType={ValidationType.Inn}
                    placeholder=" "
                    name="inn"
                    inputType={InputType.Number}
                    errors={errors}
                    onChange={(event) =>
                      setValue('inn', event.target.value.replace(/[^0-9]/g, ''))
                    }
                    className={s.input}
                    register={register}
                    required
                    resetField={resetField}
                    data-qa="section_input"
                    onBlur={(event) => {
                      if (event.currentTarget.value) {
                        fillFormField({
                          formType: 'popup',
                          fieldName: 'ИНН',
                          popupPlace: 'body',
                          buttonId: 'partners-body',
                        });
                      }
                    }}
                  />
                )}
              </div>

              {!short && !legalEntity && (
                <Checkbox
                  className={s.checkbox}
                  errors={errors}
                  required={false}
                  register={register}
                  name="isNaturalPerson"
                  value="true"
                  label={fieldsNames?.checkbox?.text ?? 'Я физлицо'}
                />
              )}

              {!short && (
                <>
                  <div className={s.double}>
                    <Input
                      name="company"
                      validationType={ValidationType.Company}
                      inputType={InputType.Text}
                      label={fieldsNames?.company?.label ?? 'Название компании'}
                      required
                      errors={errors}
                      register={register}
                      disabled={isPhisic}
                      resetField={resetField}
                      className={s.input}
                      onBlur={(event) => {
                        if (event.currentTarget.value) {
                          sendFormFillAnalytics('company');
                        }
                      }}
                    />
                    <Input
                      name="position"
                      validationType={ValidationType.Position}
                      inputType={InputType.Text}
                      label={fieldsNames?.position?.label ?? 'Должность'}
                      errors={errors}
                      register={register}
                      disabled={isPhisic}
                      resetField={resetField}
                      required
                      className={s.input}
                      onBlur={(event) => {
                        if (event.currentTarget.value) {
                          sendFormFillAnalytics('position');
                        }
                      }}
                    />
                  </div>

                  {multipleComment ? (
                    <MultiSelectControl
                      options={multipleComment.options}
                      control={control}
                      name={multipleComment.name}
                      label={multipleComment.label}
                      errors={errors}
                      required
                    />
                  ) : (
                    <TextArea
                      label={fieldsNames?.comment?.label ?? 'Комментарий'}
                      name="comment"
                      required={false}
                      errors={errors}
                      register={register}
                      resetField={resetField}
                      className={s.textarea}
                      onBlur={(event) => {
                        if (event.currentTarget.value) {
                          sendFormFillAnalytics('comment');
                        }
                      }}
                    />
                  )}
                </>
              )}

              <UserAgreementCheckboxes
                errors={errors}
                register={register}
                agreementName={agreementName}
                sendFormFillAnalytics={sendFormFillAnalytics}
                withSubscribeForMailing={withSubscribeForMailing}
                fieldsNames={{
                  userAgreement: fieldsNames?.userAgreement,
                  checkboxSubscribe: fieldsNames?.checkboxSubscribe,
                }}
                isSubscribeForMailingRequired={isSubscribeForMailingRequired}
              />

              <Submit>{buttonTitle}</Submit>
            </form>
            {isSendingStatus && <LoadingIcon />}
          </div>
          <NoticeBlock
            formStatus={formStatus}
            successNotice={formSuccessNotice}
            analyticsButtonId={analyticsButtonId}
            errorNotice={formErrorNotice}
            setFormStatus={setFormStatus}
            formVariant={formVariant}
            showNotice={showNotice}
          />
        </div>
      );
  }
}

const InnerForm = forwardRef<HTMLElement, FormBaseProps>(
  ({ id, classNames = {}, ...props }, ref) => {
    return (
      <section ref={ref} className={cn(s.inner, classNames?.inner)} id={id}>
        <Wrapper className={s.wrapper}>
          <FormBase
            {...props}
            successfulSubmissonFormAnalytics={
              props.successfulSubmissonFormAnalytics && {
                zoneclick:
                  props.successfulSubmissonFormAnalytics.zoneclick || 'B',
                formname: props.successfulSubmissonFormAnalytics?.formname,
              }
            }
          />
        </Wrapper>
      </section>
    );
  },
);

InnerForm.displayName = 'InnerForm';

function ModalForm({
  modalClose,
  productTitle,
  eventCategory,
  ...props
}: FormBaseProps) {
  return (
    <>
      {modalClose && (
        <PortalModal
          className={s.modal}
          withoutDefaultCloseIcon
          portalClassName={s.portal}
          onClickForClose={modalClose}
        >
          <div className={s.wrapper}>
            <button className={s.closeWrapper} onClick={modalClose}>
              <CrossIcon className={s.close} />
            </button>
            <FormBase
              // FIXME: почему хардкод? В props так же есть dataLayer
              dataLayer={{
                event: 'custom_event',
                event_category: eventCategory || 'consultation LeadForm',
                event_action: 'formSuccess',
                event_label: productTitle ? productTitle : 'mainPage',
              }}
              {...props}
              successfulSubmissonFormAnalytics={
                props.successfulSubmissonFormAnalytics && {
                  zoneclick:
                    props.successfulSubmissonFormAnalytics.zoneclick || 'P',
                  formname: props.successfulSubmissonFormAnalytics?.formname,
                }
              }
              allFormsSentIsPopup
            />
          </div>
        </PortalModal>
      )}
    </>
  );
}

// Варианты формы:
// modal - для модальных окон DONE
// inner - для встроенных в страницу DONE
// hero - для встраивания в hero блок WIP

// Конфигурация формы:
// short - короткая форма - DONE
// legalEntity - форма для юр лиц (без чебокса) - DONE

export const Form = forwardRef<HTMLDivElement, FormBaseProps>(
  ({ formVariant, ...props }, ref) => {
    return (
      <>
        {formVariant === 'modal' && (
          <ModalForm formVariant={formVariant} {...props} />
        )}
        {formVariant === 'inner' && (
          <InnerForm formVariant={formVariant} {...props} ref={ref} />
        )}
      </>
    );
  },
);

Form.displayName = 'Form';
